import * as _lru_map2 from "lru_map";
var _lru_map = _lru_map2;
try {
  if ("default" in _lru_map2) _lru_map = _lru_map2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Storage = void 0;
const lru_map_1 = _lru_map;
class Storage {
  constructor(options = {
    max: Storage.MAX_ELEMENTS
  }) {
    this.cache = new lru_map_1.LRUMap(options.max);
    this.blockHeights = new Map();
  }
  load(blockRef) {
    const noBlockId = !("blockId" in blockRef);
    if (noBlockId) return undefined;
    let blockId = blockRef.blockId;
    // block hash is passed, so get its corresponding block height
    if (blockId.toString().length == 44) {
      blockId = this.blockHeights.get(blockId.toString());
    }
    // get cached values for the given block height
    return this.cache.get(blockId);
  }
  save(blockHash, {
    blockHeight,
    blockTimestamp,
    contractCode,
    contractState
  }) {
    this.blockHeights.set(blockHash, blockHeight);
    this.cache.set(blockHeight, {
      blockHeight,
      blockTimestamp,
      contractCode,
      contractState
    });
  }
}
exports.Storage = Storage;
Storage.MAX_ELEMENTS = 100;
export default exports;