import * as _signers2 from "@near-js/signers";
var _signers = _signers2;
try {
  if ("default" in _signers2) _signers = _signers2.default;
} catch (e) {}
import * as _providers2 from "@near-js/providers";
var _providers = _providers2;
try {
  if ("default" in _providers2) _providers = _providers2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Connection = void 0;
const signers_1 = _signers;
const providers_1 = _providers;
/**
 * @param config Contains connection info details
 * @returns {Provider}
 */
function getProvider(config) {
  switch (config.type) {
    case undefined:
      return config;
    case "JsonRpcProvider":
      return new providers_1.JsonRpcProvider(Object.assign({}, config.args));
    default:
      throw new Error(`Unknown provider type ${config.type}`);
  }
}
/**
 * @param config Contains connection info details
 * @returns {Signer}
 */
function getSigner(config) {
  switch (config.type) {
    case undefined:
      return config;
    case "InMemorySigner":
      {
        return new signers_1.InMemorySigner(config.keyStore);
      }
    default:
      throw new Error(`Unknown signer type ${config.type}`);
  }
}
/**
 * Connects an account to a given network via a given provider
 */
class Connection {
  constructor(networkId, provider, signer, jsvmAccountId) {
    this.networkId = networkId;
    this.provider = provider;
    this.signer = signer;
    this.jsvmAccountId = jsvmAccountId;
  }
  /**
   * @param config Contains connection info details
   */
  static fromConfig(config) {
    const provider = getProvider(config.provider);
    const signer = getSigner(config.signer);
    return new Connection(config.networkId, provider, signer, config.jsvmAccountId);
  }
}
exports.Connection = Connection;
export default exports;